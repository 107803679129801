<template>
    <section class="home d-flex pt-5">
        <div class="container pt-5 pt-md-0">
            <div class="row h-100">
                <div class="col-md-6 col-xl-7 d-flex flex-column justify-content-center align-items-center text">
                    <h1 class="display-3 font-weight-bold text-center pb-2"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        高滲玻尿酸療程
                    </h1>
                    <h2 class="text-center pb-4"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        2月加推 <br>
                        美白．保濕．緊緻
                    </h2>
                    <button class="btn btn-lg btn-light rounded-0"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            v-on:click="scrollToForm">
                        立即預約
                    </button>
                </div>
                <div class="col-md-6 col-xl-5 d-flex justify-content-md-center">
                    <div class="position-relative img-wrapper">
                        <picture class="model-wrapper">
                            <source type="image/webp"
                                    srcset="../../../assets/campaign/ha-b5-aqua-spa/model.webp">
                            <img class="model"
                                 alt="model"
                                 src="../../../assets/campaign/ha-b5-aqua-spa/model.png"
                                 data-aos="fade"
                                 data-aos-duration="750">
                        </picture>

                        <div class="position-absolute font-weight-bolder price">
                            <div class="position-absolute bar"></div>
                            $199
                            <div class="position-absolute bar"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "HaB5AquaSpaHome",
        methods: {
            scrollToForm () {
                document.getElementById("enquiry").scrollIntoView({
                    "behavior": "smooth"
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "./mixins.scss";
    @import "./variables.scss";

    .home {
        background-color: $background-color;
        color: $text-color-light;
        overflow-x: hidden;

        .text {
            z-index: 1;

            h1 {
                text-shadow: 0 2px 0.5px rgba(0,0,0,.75);
            }

            h2 {
                text-shadow: 0 2px 0.5px rgba(0,0,0,.75);
            }

            button {
                // box-shadow: 2px 2px 0.5px rgba(0,0,0,.75);
            }
        }

        .img-wrapper {
            & > .model-wrapper {
                max-height: 100vh;

                & > .model {
                    height: 100%;
                    width: auto;
                }
            }

            & > .price {
                font-size: 60px;
                top: 12.5%;
                left: -5%;
                transform: rotate(15deg) skewY(10deg);
                text-shadow: 0 2px 0.5px rgba(0,0,0,.75);

                animation-name: fade-loop-in;
                animation-duration: 1500ms;
                animation-iteration-count: infinite;
                animation-direction: alternate;

                @include sm {
                    font-size: 78px;
                    top: 12.5%;
                    left: -5%;
                }

                @include md {
                    font-size: 82px;
                    top: 16%;
                    left: -8%;
                }

                .bar {
                    //min-width: 0;
                    min-width: 100%;
                    height: 6px;

                    background-color: rgba(255, 255, 255, 1);

                    /*animation-name: line-drawing;
                    animation-duration: 1500ms;
                    animation-timing-function: linear;
                    animation-iteration-count: infinite;
                    animation-direction: alternate;
                    animation-fill-mode: forwards;*/

                    box-shadow: 2px 2px 0.5px rgba(0, 0, 0, 0.25);

                    &:first-child {
                        top: 0;
                        transform: skewY(12deg);
                    }

                    &:last-child {
                        bottom: 0;
                        transform: skewY(-12deg);
                    }
                }
            }
        }
    }

    @keyframes fade-loop-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /*@keyframes line-drawing {
        0% {
            min-width: 0;
            height: 6px;
        }

        100% {
            min-width: 100%;
            height: 8px;
        }
    }*/
</style>
