<template>
    <article class="ha-b5-aqua-spa">
        <ha-b5-aqua-spa-home></ha-b5-aqua-spa-home>
        <ha-b5-aqua-spa-causes></ha-b5-aqua-spa-causes>
        <ha-b5-aqua-spa-features></ha-b5-aqua-spa-features>
        <ha-b5-aqua-spa-info1></ha-b5-aqua-spa-info1>
        <ha-b5-aqua-spa-info2></ha-b5-aqua-spa-info2>
        <enquiry duplicate-to-g-sheet
                 g-sheet-id="AKfycby0oOleOEDprHZvi_zT79RERVLvoyKNJAFkEePI"
                 v-bind:with-booking-option="false"
                 v-bind:with-other-treatments="false"
                 v-bind:treatment-id="treatmentId">
            預約高滲玻尿酸水療護理
        </enquiry>
    </article>
</template>

<script>
    import Enquiry from "./Enquiry";
    import HaB5AquaSpaHome from "./HaB5AquaSpa/Home";
    import HaB5AquaSpaCauses from "./HaB5AquaSpa/Causes";
    import HaB5AquaSpaFeatures from "./HaB5AquaSpa/Features";
    import HaB5AquaSpaInfo1 from "./HaB5AquaSpa/Info1";
    import HaB5AquaSpaInfo2 from "./HaB5AquaSpa/Info2";
    export default {
        name: "HaB5AquaSpa",
        components: {
            HaB5AquaSpaInfo2,
            HaB5AquaSpaInfo1,
            HaB5AquaSpaFeatures,
            HaB5AquaSpaCauses,
            HaB5AquaSpaHome,
            Enquiry
        },
        metaInfo () {
            return {
                title: this.$sanitize(this.treatment.title[this.$i18n.locale]),
                meta: [{
                    "name": "description",
                    "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                    "vmid": "description",
                }, {
                    "property": "og:title",
                    "content": this.$sanitize(this.treatment.title[this.$i18n.locale]),
                    "template": "%s | marier Skincare 曼肌兒",
                    "vmid": "og:title"
                }, {
                    "property": "og:description",
                    "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                    "vmid": "og:description"
                }, {
                    "property": "og:type",
                    "content": "profile",
                    "vmid": "og:type"
                }, {
                    "property": "fb:app_id",
                    "content": "148635335285458",
                    "vmid": "fb:app_id"
                }, /*{
                    "property": "og:image",
                    "content": `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/product/image/${ this.product.images[0] }`,
                    "vmid": "og:image"
                }*/]
            }
        },
        props: {
            "treatmentId": {
                type: String,
                required: true,
            },
        },
        data () {
            return {

            }
        },
        computed: {
            treatments () {
                return this.$store.state.mall.salon.treatments;
            },

            treatment () {
                const target = this.treatments
                    .find(
                        treatment => treatment._id === this.treatmentId
                    );
                if (target) {
                    return target;
                }
                return {
                    title: {
                        "en": "",
                        "zh-hk": "",
                        "zh-cn": ""
                    },
                    subtitle: {
                        "en": "",
                        "zh-hk": "",
                        "zh-cn": ""
                    },
                    availableBranches: [],
                    images: [],
                    cover: "",
                };
            },
        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    .ha-b5-aqua-spa {

    }
</style>
