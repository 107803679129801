<template>
    <section class="causes">
        <div class="container py-5 py-md-0">
            <div class="row">
                <div class="col-md-2 d-block d-md-none">
                    <h1 class="display-4 font-weight-bold text-center mb-5 title"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        四大功效
                    </h1>
                </div>
                <div class="col-md-2 d-none d-md-flex flex-row justify-content-center">
                    <div class="font-weight-bolder d-flex flex-column title-columns title">
                        <div data-aos="fade-up"
                             data-aos-duration="1000">
                            四
                        </div>
                        <div data-aos="fade-up"
                             data-aos-duration="1000">
                            大
                        </div>
                        <div data-aos="fade-up"
                             data-aos-duration="1000">
                            功
                        </div>
                        <div data-aos="fade-up"
                             data-aos-duration="1000">
                            效
                        </div>
                    </div>
                </div>
                <div class="col-md-10 py-0 py-md-5 d-flex flex-column justify-content-center align-items-center"
                     data-aos="fade"
                     data-aos-duration="1000">
                    <h2 class="d-flex align-items-center mb-5">
                        皺紋減少 40%↓
                    </h2>
                    <h2 class="d-flex align-items-center mb-5">
                        皮膚含水量提升 96%↑
                    </h2>
                    <h2 class="d-flex align-items-center mb-5">
                        緊緻度提升 55%↑
                    </h2>
                    <h2 class="d-flex align-items-center mb-0">
                        彈性提升 55%↑
                    </h2>
                    <p class="align-self-end">
                        # 療程效果因人而異
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "HaB5AquaSpaFeatures"
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .causes {
        background-color: $background-color-alt-2;
        color: $text-color-dark;

        .title {
            color: $text-color-title;
        }

        .title-columns {
            font-size: 4rem;

            & > * {
                flex: 1;
            }
        }
    }
</style>
