<template>
    <section class="causes">
        <div class="container py-5 py-md-0">
            <div class="row h-100">
                <div class="col-md-2 d-block d-md-none">
                    <h1 class="display-4 font-weight-bold text-center mb-5"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        皮膚缺水<br>老化因素
                    </h1>
                </div>
                <div class="col-md-2 d-none d-md-flex flex-row justify-content-center">
                    <div class="font-weight-bolder d-flex flex-column title-columns">
                        <div data-aos="fade-up"
                             data-aos-duration="1000">
                            皮
                        </div>
                        <div data-aos="fade-up"
                             data-aos-duration="1000">
                            膚
                        </div>
                        <div data-aos="fade-up"
                             data-aos-duration="1000">
                            缺
                        </div>
                        <div data-aos="fade-up"
                             data-aos-duration="1000">
                            水
                        </div>
                    </div>
                    <div class="font-weight-bolder d-flex flex-column title-columns">
                        <div data-aos="fade-up"
                             data-aos-duration="1000">
                            老
                        </div>
                        <div data-aos="fade-up"
                             data-aos-duration="1000">
                            化
                        </div>
                        <div data-aos="fade-up"
                             data-aos-duration="1000">
                            因
                        </div>
                        <div data-aos="fade-up"
                             data-aos-duration="1000">
                            素
                        </div>
                    </div>
                </div>
                <div class="col-md-10 py-0 py-md-5 d-flex flex-column justify-content-center"
                     data-aos="fade"
                     data-aos-duration="1000">
                    <h3 class="text-justify mb-4">
                        大量研究表明，人體皮膚角質層水分含量約佔15%至30%，低於此值會使人不適。長期更會出現皮膚乾燥龜裂，令皮膚失去彈性、皺紋鬆弛、顯出老態。
                    </h3>
                    <h3 class="text-justify">
                        在乾燥冬天角質層水分流失的情況更嚴峻，因此更應該把握時機，以玻尿酸這個「補水皇」去為肌膚作好衰老防禦。
                    </h3>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "HaB5AquaSpaCauses"
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .causes {
        background-color: $background-color-alt;
        color: $text-color-light;

        .title-columns {
            font-size: 4rem;

            &:last-child {
                & > *:first-child {
                    padding-top: 2rem;
                }
            }
        }
    }
</style>
